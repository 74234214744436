<template>
  <div class="box">
    <div class="box-header with-border">
      <h3>
        <previous-route-block/>

        {{ page_title }} #{{ taskId }}

        <span v-if="task && (task.creator || task.client)" class="label font-small label-default">
          {{ task.creator ? 'Сотрудник' : ((!task.creator && task.client) ? 'Клиент' : '') }}
        </span>
      </h3>
    </div>

    <div class="box-body">
      <div class="row" v-if="task">
        <div class="col-xxl-3 col-xl-4 col-lg-4 col-md-4 col-sm-12 col-xs-12">
          <div class="box box-primary">
            <div class="box-body">
              <h5 class="box-title pointer" v-on:click="toggleFormTask">
                <a class="collapsed">
                  <i v-bind:class="show_form_task ? 'fa-caret-square-up' : 'fa-caret-square-down'"
                     class="font-scale-3 text-blue fas padding-r-5"/>
                  <strong>Информация</strong>

                  <template v-if="task.tags.length">
                    <span v-for="tag in task.tags" class="label font-small-xxs mt-1 pull-right"
                          :class="tag.label_color_class">
                      {{ tag.name }}
                    </span>
                  </template>
                </a>
              </h5>

              <transition name="fade">
                <div class="collapse" v-bind:class="show_form_task ? 'in' : ''" v-show="show_form_task"
                     v-show-slide="show_form_task">

                  <hr class="mb-1 mt-1"/>

                  <ul class="list-group list-group-unbordered">
                    <li class="list-group-item font-scale-1">
                      <strong>Тип</strong>

                      <a class="pull-right">
                        <strong>{{ task.type.name }}</strong>
                      </a>
                    </li>
                    <li v-if="task.type_id === 1 && task.last_comment && task.last_comment.length" class="list-group-item font-scale">
                      <task-item-comment-block :task_last_comment="task.last_comment[0]" :with_delimiter="false"/>
                    </li>
                    <li v-if="task.car" class="list-group-item">
                      <strong>Автомобиль</strong>

                      <a class="pull-right">
                        <strong class="margin-r-5">{{ task.car.title }}</strong>
                        <small>(ID: {{ task.car.id }} {{ task.car.branch.title }})</small>
                        <label v-if="task.car.is_deleted"
                               class="label label-default text-white font-small-xs font-medium">
                          В архиве
                        </label>
                      </a>
                    </li>
                    <li v-if="task.client" class="list-group-item">
                      <strong>Клиент</strong>

                      <a class="pull-right">
                        <strong>
                          {{ task.client.name }}
                        </strong>
                        <small>(ID: {{ task.client.id }})</small>
                      </a>
                      <br/>
                      <span class="pull-right">
                        <i class="fa fa-phone-square font-small"></i>
                        <a :href="'tel:+' + task.client.phone" @click="callLogging()" class="font-medium">
                          {{ task.client.phone }}
                        </a>
                      </span>
                      <br/>
                      <a @click="callLogging()"
                         :href="'tel:+' + task.client.phone"
                         class="pull-right mt-1 pointer">
                        <i v-if="is_called === 1" class="fa fa-phone-square status-phone" style="color: green" />
                        <span v-else="is_called == 0">
                          <i class="fa fa-phone-square status-phone" style="color:red" />
                          <small class="font-small text-black"
                                 v-if="task.type_status_id === 10 && (Math.floor((new Date() - new Date(task.created_at)) / (1000 * 60)) <= 30)">
                            <br />
                            <strong>{{ (30 - Math.floor(((new Date()) - (new Date(task.created_at))) / (1000 * 60))) }}</strong> мин.
                          </small>
                          <small class="font-small text-black"
                                 v-if="task.type_status_id === 11 && (Math.floor((new Date() - new Date(task.created_at)) / (1000 * 60)) <= 5)">
                            <br />
                            <strong>{{ (5 - Math.floor(((new Date()) - (new Date(task.created_at))) / (1000 * 60))) }}</strong> мин.
                          </small>
                        </span>
                      </a>
                    </li>
                    <li v-if="Object.keys(task.details).length > 0" class="list-group-item pt-2 pb-3">
                      <p v-if="task.details.sale_car" class="mb-0">
                        Авто продано за <strong>{{ task.details.sale_car.sum}}$</strong>
                      </p>
                      <p v-if="task.details.date" class="mb-0">
                        <span><strong>Дата: </strong> {{ task.details.date }}</span>
                      </p>
                      <p v-if="task.details.is_official_employment || task.details.credit_terms_year ||
                          task.details.initial_fee_percent" class="mb-0">
                        <span>
                          {{ task.details.is_official_employment ? 'Официально трудоустроен, ' : '' }}
                          {{ task.details.credit_terms_year ? 'срок ' + task.details.credit_terms_year + ' г.,' : '' }}
                          аванс {{ task.details.initial_fee_percent }}%
                        </span>
                      </p>
                      <p v-if="task.details.mark || task.details.model" class="mt-1 mb-0">
                        <span>
                          <strong>
                            {{ task.details.mark ? task.details.mark.name : '' }}
                            {{ task.details.model ? task.details.model.name : '' }}
                          </strong>
                        </span>
                      </p>
                      <p v-if="task.details.year || task.details.race || task.details.link || task.details.city || task.details.region"
                         class="mb-0">
                        <span>
                          {{
                            task.details.city ?
                              'город ' + (typeof task.details.city === 'object' ? task.details.city.title : JSON.parse(task.details.city).title) + '; ' :
                              ''
                          }}
                          {{
                            task.details.region ?
                              (typeof task.details.region === 'object' ? task.details.region.title : JSON.parse(task.details.region).title) + ' область; ' :
                              ''
                          }}
                          {{ task.details.year ? task.details.year + ' год; ' : '' }}
                          {{ task.details.race ? 'пробег ' + task.details.race + ' тыс. км.; ' : '' }}
                          <a v-if="task.details.link" :href="task.details.link" target="_blank">
                            cсылка на авто
                          </a>
                        </span>
                      </p>
                      <p v-if="task.details.year_from || task.details.year_to || task.details.race_from ||
                          task.details.race_to || task.details.color || task.details.gearbox || task.details.price_from ||
                          task.details.price_to || task.details.city || task.details.engine || task.details.transmission ||
                          task.details.price" class="mb-0">
                        <span>
                          {{ task.details.engine ? 'объем двигателя ' + task.details.engine + '; ' : '' }}
                          {{
                            task.details.transmission ?
                              'привод ' +  (typeof task.details.transmission === 'object' ? task.details.transmission.title : JSON.parse(task.details.transmission).title) + ';' :
                              ''
                          }}
                          {{ task.details.year_from ? 'от ' + task.details.year_from + ' г.; ' : '' }}
                          {{ task.details.year_to ? 'до ' + task.details.year_to + ' г.; ' : '' }}
                          {{ task.details.race_from ? 'от ' + task.details.race_from + ' тыс. км.; ' : '' }}
                          {{ task.details.race_to ? 'до ' + task.details.race_to + ' тыс. км.; ' : '' }}
                          {{
                            task.details.color ?
                              'цвет ' +  (typeof task.details.color === 'object' ? task.details.color.title : JSON.parse(task.details.color).title) + ';' :
                              ''
                          }}
                          {{
                            task.details.gearbox ?
                              'КПП ' +  (typeof task.details.gearbox === 'object' ? task.details.gearbox.title : JSON.parse(task.details.gearbox).title) + ';' :
                              ''
                          }}
                          {{ task.details.price_from ? 'от ' + task.details.price_from + '$; ' : '' }}
                          {{ task.details.price_to ? 'до ' + task.details.price_to + '$; ' : '' }}
                          {{ task.details.price ? 'цена ' + task.details.price + '$;' : '' }}
                        </span>
                      </p>
                      <p v-if="task.details.bargain_sum" class="mb-0">
                        <span>
                          <strong>Сумма торга: </strong> {{ task.details.bargain_sum }}$
                          — <strong class="font-medium text-muted">
                              {{ task.car.price - task.details.bargain_sum }}$
                            </strong>
                        </span>
                      </p>
                    </li>
                    <li class="list-group-item">
                      <strong>Исполнитель</strong>

                      <a v-if="task.responsible || task.branch" class="pull-right">
                        <span v-if="task.responsible">
                          <strong>
                            {{ task.responsible.name }}
                          </strong>
                          <small>
                            (ID: {{ task.responsible.id }} {{ task.responsible.branch ? task.responsible.branch.title : '' }})
                          </small>
                        </span>
                        <span v-else>
                          <strong>
                            {{ task.branch.title }}
                          </strong>
                          <small>
                            {{ task.branch.address }}
                          </small>
                        </span>
                      </a>
                      <a v-else class="pull-right">
                        <strong class="font-medium text-muted">Не назначен</strong>
                      </a>
                    </li>
                    <li v-if="task.term_date" class="list-group-item">
                      <strong>Срок выполнения</strong>

                      <span class="pull-right">
                        {{ task.term_date }}
                      </span>
                    </li>
                  </ul>

                  <hr class="mb-2 mt-2"/>

                  <p class="text-muted text-right">
                    <small>
                      Создал
                      <strong v-if="task.creator">{{ task.creator.name }}</strong>

                      <span class="font-medium" v-if="!task.creator && task.client">
                        {{ task.client.name }}
                        <span class="text-muted">(клиент)</span>
                      </span>

                      {{ task.created_date }}
                    </small>
                    <small v-if="task.editor">
                      <br/>
                      Обновил <strong>{{ task.editor.name }}</strong>
                      {{ task.updated_date }}
                    </small>
                  </p>
                </div>
              </transition>
            </div>
          </div>

          <div class="box box-primary" v-if="([1, 7, 8, 9].includes($store.getters['auth/authUser'].role.id) ||
              $store.getters['auth/authUser'].id === task.responsible_id) && !task.is_deleted">
            <div class="box-header mb-0">
              <h5 class="box-title text-center pointer" v-on:click="toggleFormTaskType">
                <a class="collapsed">
                  <i v-bind:class="show_form_task_type ? 'fa-caret-square-up' : 'fa-caret-square-down'"
                     class="font-scale-3 text-blue fas"/>
                  Обновить статус
                </a>
              </h5>
            </div>
            <div class="box-body">
              <transition name="fade">
                <div class="collapse" v-bind:class="show_form_task_type ? 'in' : ''"
                     v-show="show_form_task_type" v-show-slide="show_form_task_type">

                  <hr class="mb-3 mt-3"/>

                  <edit-task-form :task="task"></edit-task-form>
                </div>
              </transition>
            </div>
          </div>

          <div class="box box-primary" v-if="([1, 7, 8, 9].includes($store.getters['auth/authUser'].role.id) ||
              $store.getters['auth/authUser'].id === task.creator_id) && !task.branch && !task.is_deleted">
            <div class="box-header mb-0">
              <h5 class="box-title text-center pointer" v-on:click="toggleFormTaskResponsible">
                <a class="collapsed">
                  <i v-bind:class="show_form_task_responsible ? 'fa-caret-square-up' : 'fa-caret-square-down'"
                     class="font-scale-3 text-blue fas"/>
                  Исполнитель
                </a>
              </h5>
            </div>
            <div class="box-body">
              <transition name="fade">
                <div class="collapse" v-bind:class="show_form_task_responsible ? 'in' : ''"
                     v-show="show_form_task_responsible" v-show-slide="show_form_task_responsible">

                  <hr class="mb-3 mt-3"/>

                  <edit-responsible-form :task="task"></edit-responsible-form>
                </div>
              </transition>
            </div>
          </div>
        </div>

        <div class="col-xxl-9 col-xl-8 col-lg-8 col-md-8 col-sm-12 col-xs-12">
          <vue-tabs class="panel no-border" v-model="currentTabItem" @tab-change="handleTabChange">
            <v-tab class="nav-tab-content"
                   key="comments"
                   :title="tabNames.comments">
              <task-comments :taskId="task.id"
                             :isDeleted="task.is_deleted"/>
            </v-tab>
            <v-tab class="nav-tab-content"
                   key="history"
                   :title="tabNames.history">
              <task-history v-if="currentTabItem === 1"
                            :taskId="task.id"/>
            </v-tab>
            <v-tab v-if="task.type_id === 12" class="nav-tab-content"
                   key="suitable-cars"
                   :title="tabNames.suitable_cars">
              <task-suitable-cars v-if="currentTabItem === 2"
                                  :taskId="task.id"
                                  :taskDetails="task.details"/>
            </v-tab>
            <v-tab v-if="task.type_id === 5" class="nav-tab-content"
                   key="cars-search-checklists"
                   :title="tabNames.cars_search_checklists">
              <task-cars-search-checklists v-if="currentTabItem === 2"
                                  :taskId="task.id"/>
            </v-tab>
            <v-tab class="nav-tab-content"
                   key="files"
                   :title="tabNames.files">
              <task-files v-if="currentTabItem === (task.type_id !== 12 ? 2 : 3)"
                          :taskId="task.id"
                          :isDeleted="task.is_deleted"/>
            </v-tab>
            <v-tab class="nav-tab-content"
                   key="calls"
                   :title="tabNames.calls">
                            <task-calls :taskId="task.id"
                                        :calls="task.calls"/>
            </v-tab>
          </vue-tabs>
        </div>
      </div>
      <loading-processing v-else></loading-processing>
    </div>
  </div>
</template>

<script>
import Vue from 'vue';
import {VTab, VueTabs} from 'vue-nav-tabs';
import 'vue-nav-tabs/themes/vue-tabs.css';

Vue.component('task-item-comment-block', (resolve) => {
  return require(['./../item_blocks/comment-block'], resolve);
});

Vue.component('edit-task-form', (resolve) => {
  return require(['./edit-form'], resolve);
});
Vue.component('edit-responsible-form', (resolve) => {
  return require(['./edit-responsible-form'], resolve);
});
Vue.component('task-comments', (resolve) => {
  return require(['./comments'], resolve);
});
Vue.component('task-history', (resolve) => {
  return require(['./history'], resolve);
});
Vue.component('task-suitable-cars', (resolve) => {
  return require(['./suitable_cars/index'], resolve);
});
Vue.component('task-cars-search-checklists', (resolve) => {
  return require(['./cars_search_checklists/index'], resolve);
});
Vue.component('task-files', (resolve) => {
  return require(['./files'], resolve);
});
Vue.component('task-calls', (resolve) => {
  return require(['./calls'], resolve);
});
export default {
  name: 'task-details',

  components: {
    VueTabs,
    VTab,
  },

  metaInfo() {
    return {
      title: this.page_title,
    }
  },

  props: {
    taskId: {
      type: [String, Number],
      required: true,
    },
    currentTab: {
      type: [String, null],
      required: false,
      default: null,
    },
  },

  data: () => ({
    page_title: 'Задача',

    task: null,
    is_called: null,

    relations: [
      'car.branch',
      'client',
      'type',
      'type_status',
      'responsible.branch',
      'branch',
      'creator',
      'editor',
      'last_comment',
    ],
    relations_count: [
      'comments',
      'history',
      'files',
      'car_search_checklists',
      'calls'
    ],

    tabNames: {
      comments: 'Комментарии',
      history: 'История',
      suitable_cars: 'Подходящие авто',
      cars_search_checklists: 'Чек-листы автоподбор',
      files: 'Файлы',
      calls: 'Звонки',
    },

    currentTabItem: null,

    show_form_task: true,
    show_form_task_type: true,
    show_form_task_responsible: true,
  }),

  async created() {
    try {
      if (this.currentTab) {
        this.show_form_task_type = false;
        this.show_form_task_responsible = false;

        this.currentTabItem = this.currentTab;
      }

      await this.getTask();
    } catch (error) {
      errorHandler(error);
    }
  },

  mounted() {
    setTimeout(() => {
      this.tabNames.comments += ' (' + this.task.comments_count + ')';
      this.tabNames.history += ' (' + this.task.history_count + ')';
      this.tabNames.files += ' (' + this.task.files_count + ')';
      this.tabNames.cars_search_checklists += ' (' + this.task.car_search_checklists_count + ')';
      this.tabNames.calls += ' (' + this.task.calls_count + ')';
    }, 1500);
  },

  methods: {
    handleTabChange(tabIndex, newTab, oldTab){
      this.currentTabItem = tabIndex;
    },
    async toggleFormTask() {
      this.show_form_task = this.show_form_task === false;
    },
    async toggleFormTaskType() {
      this.show_form_task_type = this.show_form_task_type === false;
    },
    async toggleFormTaskResponsible() {
      this.show_form_task_responsible = this.show_form_task_responsible === false;
    },
    async getTask() {
      try {
        const task_response = await API.apiClient.get('/task/' + this.taskId, {
          params: {
            relations: this.relations,
            relations_count: this.relations_count,
          }
        });

        this.task = task_response.data;

        if (this.task.details && this.task.details.is_called === 1) {
          this.is_called = 1;
        } else {
          this.is_called = 0;
        }
      } catch (error) {
        errorHandler(error);
      }
    },
    callLogging() {
      API.apiClient.put("/tasks/add_call/" + this.task_id).then(() => {
        this.is_called = 1;
      }).catch((error) => {
        errorHandler(error);
      });
    },
  }
}
</script>

<style scoped>

  .status-phone {
    font-size: 40px;
  }
</style>
